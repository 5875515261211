<template>
  <v-container fluid>
    <h1>
      {{$t('Booker documents')}}
      <template v-if="orderId">
        {{$t('on request')}} <span class="red--text lighten-2"> {{orderId}}</span>
      </template>
    </h1>
    <search-layout v-if="!orderId"
                   single-line
                   @search="search">
      <template v-slot:searchFields>
        <v-text-field v-model="order"
                      :label="$t('Request')"
                      hide-details
                      clearable />
      </template>
    </search-layout>
    <template v-if="orderId">
      <h3 class="text--secondary">
        {{$t('Files')}}:
      </h3>
      <v-btn v-for="file in files"
             :key="file.id"
             :href="getDownloadOrderFileLink(file)"
             color="success"
             class="mb-1 mr-1">
        {{file.description}}
      </v-btn>
      <h3 class="text--secondary">
        {{$t('Financial documents')}}:
      </h3>
      <template v-for="bill in bills">
        <v-btn v-for="file in bill.files"
               :key="file.id"
               :href="getDownloadBillFileLink(bill, file)"
               color="success"
               class="mb-1 mr-1">
          {{file.description}}
        </v-btn>
      </template>
    </template>
  </v-container>
</template>

<script>
import SearchLayout from '@/components/DataTable/SearchLayout';

export default {
  name: 'PccBookerDocumentList',
  components: {SearchLayout},
  props: {
    orderId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data () {
    return {
      loading: false,
      order: '',
      files: [],
      bills: [],
    };
  },
  watch: {
    orderId (value) {
      if (value) {
        this.loadBills();
        this.loadFiles();
      }
    },
  },
  created () {
    if (this.orderId) {
      this.loadFiles();
      this.loadBills();
    }
  },
  methods: {
    async loadFiles () {
      try {
        this.loading  = true;
        const path    = ['orders', this.orderId, 'files'];
        const success = await this.API.get.progress(path);
        this.files    = success.data;
      } finally {
        this.loading = false;
      }
    },
    async loadBills () {
      try {
        this.loading  = true;
        const path    = ['printable-bills', this.orderId];
        const success = await this.API.get.progress(path);
        this.bills    = success.data;
      } finally {
        this.loading = false;
      }
    },
    search () {
      if (this.order) {
        this.$router.push({name: 'BookerDocuments', params: {orderId: this.order}});
      }
    },
    getDownloadOrderFileLink (file) {
      return this.API.getBaseUrl() + '/orders/' + this.orderId + '/files/' + file.id + '?bearer=' + this.API.getToken();
    },
    getDownloadBillFileLink (bill, file) {
      return this.API.getBaseUrl() + '/printable-bills/' + bill.id + '/order/' + this.orderId + '/file/' + file.id + '?bearer=' + this.API.getToken();
    },
  },
};
</script>

<style scoped>

</style>

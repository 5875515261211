<template>
  <v-container>
    <!--    <div class="text-center">-->
    <!--      <h3 class="grey&#45;&#45;text darken-2 font-weight-regular mt-14">-->
    <!--        Руководство пользователя, версия 4.0 beta-->
    <!--      </h3>-->
    <!--      <h1 class="mt-4">-->
    <!--        Личный кабинет клиента-->
    <!--      </h1>-->
    <!--      <h2 class="accent&#45;&#45;text font-weight-regular">-->
    <!--        transportir.online-->
    <!--      </h2>-->
    <!--    </div>-->
    <!--    <h3 class="my-5">-->
    <!--      Уважаемый клиент группы компаний ТРАНСПОРТИР!-->
    <!--    </h3>-->
    <!--    <p>-->
    <!--      Мы убеждены, что никакой личный кабинет не в состоянии заменить-->
    <!--      простого человеческого общения, и поэтому решили вынести ряд-->
    <!--      механистических, рутинных процедур в виртуальное пространство с-->
    <!--      тем, чтобы они больше не мешали нашему живому общению.-->
    <!--    </p>-->
    <!--    <p>-->
    <!--      Сервис transportir.online поможет Вам размещать заявки, отслеживать ход их выполнения, отслеживать оплаты, своевременно получать копии счетов,-->
    <!--      закрывающих и транспортных документов, получать и отправлять любые документы, а также неограниченно хранить их на нашем сервере.-->
    <!--    </p>-->
    <!--    <h2 class="primary&#45;&#45;text">-->
    <!--      Создание заявки-->
    <!--    </h2>-->
    <!--    <v-divider class="mb-3" />-->
    <!--    <p>-->
    <!--      С помощью мастера заполнения заявок Вы можете создать заявку на перевозку, которая сразу же поступит на утверждение Вашему персональному-->
    <!--      менеджеру.-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-1.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 2"-->
    <!--         align="bottom"-->
    <!--         width="750"-->
    <!--         height="362"-->
    <!--         border="0">-->
    <!--    <p class="-tip">-->
    <!--      <b>Лайфхак!</b> — Похожие заявки необязательно создавать заново — достаточно сделать копию уже имеющейся, нажав на иконку-->
    <!--      документа в правой части листинга.-->
    <!--    </p>-->

    <!--    <p>Если же заявка уникальная, нажмите «Новая заявка» в общем листинге заявок.</p>-->
    <!--    <p>В выпадающем списке выберете Договор, по которому планируется осуществление заявки, тип транспортного средства.</p>-->

    <!--    <img src="/manual/screen-2.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 5"-->
    <!--         align="bottom"-->
    <!--         width="675"-->
    <!--         height="117"-->
    <!--         border="0">-->
    <!--    <p>Затем последовательно заполните области «Загрузка», «Выгрузка», «Таможня загрузки», «Таможня выгрузки» блока МАРШРУТ. </p>-->
    <!--    <img src="/manual/screen-3.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 6"-->
    <!--         align="bottom"-->
    <!--         width="765"-->
    <!--         height="620"-->
    <!--         border="0">-->
    <!--    <p> При клике мышкой на любой из областей, справа будет появляться форма, которую необходимо заполнить. </p>-->
    <!--    <p> Например, нажав на область «Выгрузка», справа появится форма «Информация о выгрузке».</p>-->
    <!--    <p>-->
    <!--      <img src="/manual/screen-4.png"-->
    <!--           class="image"-->
    <!--           name="Рисунок 7"-->
    <!--           width="304"-->
    <!--           height="403"-->
    <!--           border="0">-->
    <!--    </p><p> Заполните необходимую информацию в форме, и она автоматически перенесется в блок МАРШРУТ. </p>-->
    <!--    <img src="/manual/screen-5.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 8"-->
    <!--         width="308"-->
    <!--         height="462"-->
    <!--         border="0">-->
    <!--    <p>Закончив с описанием маршрута перевозки, Переходите к блоку «Информация о грузе».</p>-->
    <!--    <p>Ничего нового, так же нажмите на область и заполните данными форму справа.</p>-->
    <!--    <p>И наконец, последний блок для заполнения – «Стоимость услуг»:</p>-->
    <!--    <img src="/manual/screen-6.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 10"-->
    <!--         width="709"-->
    <!--         height="127"-->
    <!--         border="0">-->
    <!--    <p>Заявка заполнена! Теперь ее можно сохранить, нажав кнопку «Сохранить» вверху заявки.</p>-->
    <!--    <img src="/manual/screen-7.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 9"-->
    <!--         width="554"-->
    <!--         height="148"-->
    <!--         border="0">-->
    <!--    <h2 class="primary&#45;&#45;text">-->
    <!--      Управление заявками-->
    <!--    </h2>-->
    <!--    <v-divider class="mb-2" />-->
    <!--    <p>После сохранения заявки ей будет присвоен номер и Вам становятся доступны все функции управления заявками.</p>-->
    <!--    <img src="/manual/screen-8.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 11"-->
    <!--         width="354"-->
    <!--         height="204"-->
    <!--         border="0">-->
    <!--    <p>Вы сможете отслеживать стадии перевозки Вашего груза. Они будут появляться по мере совершения того или иного события.</p>-->
    <!--    <p>-->
    <!--      В отдельном блоке «Финансовые документы» будут появляться копии счетов на оплату, а так же акты и счета-фактуры сразу после их выставления.-->
    <!--      Скачать любой файл можно одним кликом.-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-9.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 12"-->
    <!--         width="640"-->
    <!--         height="131"-->
    <!--         border="0">-->
    <!--    <p>-->
    <!--      Если есть необходимость отправить Вашему персональному менеджеру файлы, относящиеся к перевозке, просто перетащите их с Вашего рабочего стола в-->
    <!--      область «Перетащите файлы». Так же в этой области будут доступны файлы, прикрепленные Вашим персональным менеджером, например копия <span-->
    <!--        lang="en-US">CMR</span>.-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-10.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 13"-->
    <!--         width="468"-->
    <!--         height="162"-->
    <!--         border="0">-->
    <!--    <p>-->
    <!--      Помимо этого в блоке «Файлы» будет доступна <span lang="en-US">pdf</span>-заявка на перевозку, после подтверждения ее Вашим персональным-->
    <!--      менеджером. Заявку можно распечатать и подписать.-->
    <!--    </p>-->
    <!--    <h2 class="primary&#45;&#45;text">-->
    <!--      Статусы заявок-->
    <!--    </h2>-->
    <!--    <v-divider class="mb-2" />-->
    <!--    <p>-->
    <!--      После создания, заявка находится в статусе «Не подтверждена менеджером». Как только Ваш персональный менеджер проверит заявку и подтвердит ее –-->
    <!--      это сообщение исчезнет и в блоке «Файлы» появится файл с <span lang="en-US">pdf</span>-заявкой.-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-11.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 14"-->
    <!--         width="608"-->
    <!--         height="148"-->
    <!--         border="0">-->
    <!--    <p>-->
    <!--      Если Ваш персональный менеджер внес со своей стороны изменения в заявку, в такой заявке статус поменяется на: «Необходимо подтвердить с вашей-->
    <!--      стороны».-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-12.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 15"-->
    <!--         width="608"-->
    <!--         height="178"-->
    <!--         border="0">-->
    <!--    <p> В процессе перевозки заявка находится в статусе «В работе». В листинге заказов строчки с такими заявками имеют белый цвет. </p>-->
    <!--    <img src="/manual/screen-13.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 16"-->
    <!--         width="608"-->
    <!--         height="104"-->
    <!--         border="0">-->
    <!--    <p>-->
    <!--      Через 10 дней после завершения перевозки заявки переходят в статус «в архиве». Никакие изменения в такой заявке уже сделать нельзя. В листинге-->
    <!--      заказов строчки с такими заявками подкрашены серым цветом.-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-14.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 17"-->
    <!--         width="608"-->
    <!--         height="129"-->
    <!--         border="0">-->
    <!--    <h2 class="primary&#45;&#45;text">-->
    <!--      Неоплаченные счета-->
    <!--    </h2>-->
    <!--    <v-divider class="mb-2" />-->
    <!--    <p>-->
    <!--      В личном кабинете отдельно предусмотрен раздел Счета. В этом разделе видна информация по неоплаченным счетам за перевозки со сроками оплаты.-->
    <!--      Если срок оплаты истек, но счет еще не оплачен, показывается количество дней просрочки.-->
    <!--    </p>-->
    <!--    <img src="/manual/screen-15.png"-->
    <!--         class="image"-->
    <!--         name="Рисунок 18"-->
    <!--         width="664"-->
    <!--         height="156"-->
    <!--         border="0">-->
    <!--    <h2 class="primary&#45;&#45;text">-->
    <!--      Техподдержка-->
    <!--    </h2>-->
    <!--    <v-divider class="mb-2" />-->
    <!--    <p>-->
    <!--      Пожалуйста, свяжитесь с нашей службой техподдержки и убедитесь,-->
    <!--      что нам не все равно, как Вы себя чувствуете.-->
    <!--    </p>-->
    <!--    <p>-->
    <!--      Тел.: +7 495 229-14-79, <br>-->
    <!--      Моб.: +7 916 373-06-40, <br>-->
    <!--      E-mail: pcc@transportir.com-->
    <!--    </p>-->
  </v-container>
</template>

<script>
export default {
  name: 'PccManual',
};
</script>

<style scoped>
/*.image {*/
/*    display: block;*/
/*    margin: 3rem auto;*/
/*}*/
</style>
